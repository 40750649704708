import "./index.scss";

interface BigTitleParams {
  firstLine: string;
  title: string;
}

export default function BigTitle(props: BigTitleParams) {
  const { firstLine, title } = props;
  return (
    <div className="big-title-container">
      <div className="first-line">{firstLine}</div>
      <div className="title">{title}</div>
    </div>
  );
}
