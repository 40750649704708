export const fieldes = [
  {
      "field": "Sensors and IoT system architecture development for healthcare",
      "topices": [
          "Sensor design",
          "Sensor or IoT system evaluation",
          "New architectures of sensor or IoT system",
          "Life-logging devices and technologies"
      ]
  },
  {
      "field": "Cloud computing for healthcare",
      "topices": [
          "Cloud technologies for IoT healthcare",
          "Service-oriented technologies for IoT healthcare",
          "Evaluations of Cloud-based healthcare applications",
          "New cloud infrastructure development for IoT healthcare"
      ]
  },
  {
      "field": "Knowledge discovery and data mining for healthcare",
      "topices": [
          "IoT Data management",
          "Mining and exploration framework for healthcare data",
          "Digital health",
          "Knowledge discovery on IoT healthcare data",
          "Predictive modelling for improving healthcare",
          "Big data analytics, machine learning algorithms and scalable algorithms",
          "Optimization of healthcare systems and data transmission",
          "Novel visualization methods for health data"
      ]
  },
  {
      "field": "IoT data integration and management for healthcare",
      "topices": [
          "Network communication for health sensor data",
          "Wearable sensor and data integration for healthcare",
          "Standards and interoperability in IoT healthcare",
          "Data fusion, integration, knowledge management and engineering",
          "Emerging informatics framework for big-data",
          "Big data and analytic for improving management of healthcare institutions"
      ]
  },
  {
      "field": "Robotics applications for healthcare",
      "topices": [
          "Robotics-based healthcare applications",
          "Robotics as IoT for supporting healthcare",
          "Emerging IoT and Big Data to Robotics for healthcare",
          "Cloud Robotics for healthcare"
      ]
  },
  {
      "field": "Security and privacy for healthcare",
      "topices": [
          "Socio-legal issues",
          "Ethical issues",
          "Healthcare System security",
          "Healthcare Data security and privacy"
      ]
  }
]


