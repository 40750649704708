import BigTitle from "components/BigTitle";
import "./index.scss";
import { fieldes } from "./topic";
import TopicList from "components/TopicList";
import CharacterDisplay from "components/CharacterDisplay";
import { chair, committee } from "./organizers";

export default function Page() {
  return (
    <div className="page-outer-container">
      <div className="page-container">
        <div>
          <BigTitle
            firstLine="IoTBDH-2024: The 6th International Workshop on"
            title="Internet of Things of Big Data for Healthcare"
          />
        </div>
        <div className="time-loc-info">
          <p className="line">
            held in conjunction with IEEE{" "}
            <a href="https://ieeebibm.org/BIBM2024/">BIBM 2024</a>
          </p>
          <p className="line">Lisbon, Portugal December 3-6,</p>
          <a
            style={{ fontSize: "28px", fontWeight: "bold" }}
            href="https://wi-lab.com/cyberchair/2024/bibm24/scripts/submit.php?subarea=S17&undisplay_detail=1&wh=/cyberchair/2024/bibm24/scripts/ws_submit.php"
          >
            Paper Submission
          </a>
        </div>
        <div className="text-section">
          <p className="title">CALL FOR PAPER</p>
          <p>
            Significant advancement in the Internet of Things (IoT) has
            generated a large number of opportunities for innovation in the
            field of healthcare. Due to the exponential growth of wearable
            devices and mobile applications, a promising trend in healthcare
            appears that the IoT enabled technology is transferring the
            traditional hubs of healthcare to the personalised healthcare
            systems. Successful utilization of IoT enabled technology in
            healthcare facilitates efficient diagnose and treatment, low costs,
            improvement of doctor-patient relationships, personalized treatment
            and enhanced sustainability. In addition, the big data created by
            IoT are fast expanded with valuable and crucial information for
            personalised healthcare and decision making. These data contain not
            only a sheer volume of long-term health information, but also
            complex, diverse and rich context of the health information.
            Effectively and efficiently managing, analysing, visualising and
            exploring these big data becomes necessary and brings knowledge and
            intelligence of self-empowerment to the IoT users for supporting
            wiser clinical decision-making and personalised policy formulation.
            However, empowering the utility of IoT enabled technology and
            promoting big-data as a source of innovation in healthcare systems
            is still challenging due to the lack of standardization of IoT
            system architectures, heterogeneity of wearable devices connected,
            multi-dimensionality and high volume of data set, and high demand
            for interoperability. IoTBDH-2024 will be held on December 3-6 2024
            in Lisbon, Portugal, under 2024 IEEE International Conference on
            Bioinformatics and Biomedicine (BIBM'24). The goal of IoTBDH
            workshop is to bring together researchers and practitioners from
            both academia and industry into a forum, to present the
            state-of-the-art research and applications in utilizing IoT and big
            data technology for healthcare by presenting efficient scientific
            and engineering solutions, addressing the needs and challenges for
            integration with new technologies, and providing visions for future
            research and development.
          </p>
        </div>
        <div className="text-section" style={{ padding: "12px 48px 0 48px" }}>
          <div>
            Feel free to join the authors group if you need more information:
          </div>
          <img
            src="https://iotbdh.com/images/IoTBDH2024_wechatgroup.jpg"
            width={200}
            height={200}
            alt="IoTBDH2024 wechatgroup qr code"
          />
          IoTBDH-2024 Authors Wechat Group
        </div>
        <div className="text-section">
          <p className="title">WORKSHOP THEMES AND TOPICS</p>
          <div className="shrink-column">
            <p>
              The 6th IoTBDH scope and topics can be focused on six very active
              research fields in healthcare, but are not limited to:
            </p>
            <p>
              {fieldes.map((field) => (
                <TopicList topices={field.topices} field={field.field} />
              ))}
            </p>
          </div>
        </div>
        <div className="adaptive-two-column">
          <div className="text-section">
            <p className="title">WORKSHOP STYLE</p>
            <p>
              The IoTBDH-2024 workshop is hybrid, participants can attend onsite
              or online. The virtual meeting platform is Tencent Meeting. The
              link will be released before the workshop date.
            </p>
          </div>

          <div className="text-section">
            <p className="title">IMPORTANT DATES</p>
            <p>
              <div>
                All deadlines are at 11:59pm in the Anywhere on Earth timezone.
              </div>
              <div className="item">
                <p className="item-name">Submission deadline:</p>Oct 5, 2024
              </div>
              <div className="item">
                <p className="item-name">Papers acceptance notifications:</p>Nov
                5, 2024
              </div>
              <div className="item">
                <p className="item-name">Camera Ready Deadline:</p>Nov. 21, 2024
              </div>
              <div className="item">
                <p className="item-name">Workshop date:</p>Dec 3-6, 2024
              </div>
            </p>
          </div>
        </div>
        <div className="text-section">
          <p className="title">Workshop Chair</p>
          <div className="member-display">
            {chair.map((p) => (
              <CharacterDisplay {...p} />
            ))}
          </div>
        </div>

        <div className="text-section">
          <p className="title">Committee</p>
          <div className="member-display">
            {committee.map((p) => (
              <CharacterDisplay {...p} />
            ))}
          </div>
        </div>
        <div style={{ height: "64px" }}></div>
      </div>
    </div>
  );
}
