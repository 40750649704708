import "./index.scss";

interface TopicListParams {
  field: string;
  topices: Array<string>;
}

export default function TopicList(props: TopicListParams) {
  const { field, topices } = props;
  return (
    <div className="topic-list-container">
      <div className="field-name">{field}</div>
      {topices.map((topic) => (
        <div>{`\u25CF ${topic}`}</div>
      ))}
    </div>
  );
}
