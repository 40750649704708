export const chair = [
    {
        name: 'Dr.Jun Qi',
        affiliation: 'Department of Computing, Xi’anJiaoTong-Liverpool University, China',
        email: 'jun.qi@xjtlu.edu.cn',
        image: 'Jun.jpg'
    },
    {
        name: 'Prof.Po Yang',
        affiliation: 'Department of Computer Science, University of Sheffield, UK',
        email: 'po.yang@sheffield.ac.uk',
        image: 'Po yang.jpg'
    },
    {
        name: 'Dr.Tong Liu',
        affiliation: 'Department of Computer Science, University of Sheffield, UK',
        email: 't.liu@sheffield.ac.uk',
        image: 'Tong Liu.jpg'
    }
]

export const committee = [
    {
        name: 'Prof.Jiangang Chen',
        affiliation: 'School of Communication and Electronic Engineering, East China Normal University, Shanghai, China',
        email: 'jgchen@cee.ecnu.edu.cn',
        image: 'jiangang.jpg'
    },
    {
        name: 'Prof.Yun Yang',
        affiliation: 'School of Software, Yunnan University, Kunming, China',
        email: 'yangyun@ynu.edu.cn',
        image: 'Yun Yang.png'
    },
    {
        name: 'Dr.Anum Masood',
        affiliation: 'Boston Children\'s Hospital, Boston, USA',
        email: 'anum.masood@childrens.harvard.edu ',
        image: 'Anum Masood.jpg'
    }
]
