import "./index.scss";

interface CharacterDisplayParams {
  name: string;
  affiliation: string;
  email: string;
  image: string;
}

export default function CharacterDisplay(props: CharacterDisplayParams) {
  return (
    <div className="character-display-container">
      <img
        className="image"
        src={process.env.PUBLIC_URL + `/images/${props.image}`}
        alt={`profile of ${props.name}`}
      />
      <div style={{ marginTop: "12px" }}>{props.name}</div>
      <p style={{ margin: "0", padding: "0 64px" }}>{props.affiliation}</p>
      <a href={`mailto:${props.email}`}>{props.email}</a>
    </div>
  );
}
